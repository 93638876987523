import styled from "styled-components";
import { Font, breakpoints, ColorDark } from "@/shared/Constants";
import { cardMixin } from "../../../Container/Templates/mixin";
import { Color } from "@/shared/Constants";

export const JokesMaitenaContainer = styled.div`
  ${breakpoints.phone} {
    display: none;
  }
`;

export const ParentGrid = styled.div`
  width: 100%;
  //background: ${Color.greyf1f1f1};
  padding: 10px 0;
  margin-bottom: 30px;
  height: auto;
  overflow: hidden;
  padding-bottom: 1rem;
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      //background: ${ColorDark.darkGrey};
    }
  }
  > div,
  .div4 {
    position: relative;
  }
  .div1{
    height: auto;
  }
  ${breakpoints.phone} {
    border-top: 0 none;
  }
  .halfParent {
    display: flex;
    flex-direction: column;
    gap: 13px;
    height: auto;
    .div3 {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 1rem;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          border-bottom: 1px solid ${ColorDark.greySeparator};
        }
      }
      ${breakpoints.phone} {
        border-bottom: 0px;
        padding-bottom: 0;
      }
    }
  }
  .modulo2x1 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    .mt {
      display: none;
      position: relative;
      top: 0;
      left: 0;
      padding-top: 10px;
      .volanta{
        display: none;
      }
      .title{
        display: none;
      }
    }
    picture:after {
      background: transparent;
    }
  }

  ${breakpoints.tablet} {
    .modulo2x1 {
      width: 100%;
      .mt {
        ul {
          display: none;
        }
      }
      &.visual {
        .mt {
          left: 19px;
        }
        picture {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
    }
  }

  ${breakpoints.phone} {
    max-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 0 none;
  }
`;

export const Card = styled.div`
  ${cardMixin}
`;

export const Dummy = styled.div`
  ${Font.sansSemiBold}
  font-size: 25px;
  height: 400px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

import Separator from "@/shared/Separator";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
import { Dummy, ParentGrid, Card, JokesMaitenaContainer } from "./style";
import TwoxOneVisual from "@/widgets/Nota/Templates/TwoxOneVisual";

const ContainerJokes = (props) => {

  const {
    isPreview = false,
    link,
    sectionClass = "",
    title,
    titleEnabled = true,
    marfeelEnabled = true,
    title1,
    link1,
    title2,
    link2,
    title3,
    link3,
    title4,
    link4,
    titleTarget = "_blank",
    linkImage,
  } = props;

  const marfeelTitle = title
    ? "ContainerJokes-" +
    title
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
    : "containerjokes";

  const titlesExtras = [];
  if (title1 && link1) titlesExtras.push({ title: title1, link: link1 });
  if (title2 && link2) titlesExtras.push({ title: title2, link: link2 });
  if (title3 && link3) titlesExtras.push({ title: title3, link: link3 });
  if (title4 && link4) titlesExtras.push({ title: title4, link: link4 });

  const dataTemplate = {
    ...props,
    ...props?.content?.[0],
    __extra: {
      containerName: "contenedor_humor"
    }
  }

  return (
    <JokesMaitenaContainer>
      {isPreview ? (
        <Dummy>Humor x Maitena</Dummy>
      ) : (
        !!props?.content?.[0] && (
          <>
            {title && titleEnabled && (
              <Separator
                title={"Humor x Maitena"}
                link={link}
                sectionClass={sectionClass}
                extras={titlesExtras}
                target={titleTarget}
                linkImage={linkImage}
              />
            )}
            <GridStyleColor>
              <ParentGrid
                {...(marfeelEnabled
                  ? { "data-mrf-recirculation": marfeelTitle }
                  : "")}
              >
                <Card className="div1">
                  <TwoxOneVisual {...dataTemplate} />
                </Card>
              </ParentGrid>
            </GridStyleColor>
          </>
        )
      )}
    </JokesMaitenaContainer>
  );
};

export default ContainerJokes;
